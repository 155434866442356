import Navbar from "../componenets/navbar/Navbar";
import Intro from "../componenets/intro/Intro.jsx";


export default function Home() {
    return (
        <>
        <Navbar />
        <Intro />
        </>
    )
}