import "./navbar.scss";
import { HiOutlineMenu } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";
import { useState } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <>
      <div className="navbar">
      <div className="wrapper">
        <div className="left">
          <Link to="/" alt="shan's website" className="logo">
            Shan's site💜</Link>
        </div>

        <div className="right">
          <nav>
          <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About Me</Link>
                </li>
                <li>
                  <Link to="/project">Projects</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
          </nav>
        </div>

        </div>
        
        <div className="mobilenavbar">
        <nav>
              <ul 
              className={openMenu ? 'links' : 'mobilelinks' }
              onClick= {() => setOpenMenu(false)}>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About Me</Link>
                </li>
                <li>
                  <Link to="/project">Projects</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
          </nav>
        </div>

        <div className="mobilemenu" onClick={() => setOpenMenu(!openMenu)}>
        {openMenu ? <AiOutlineClose /> : <HiOutlineMenu />}
        </div>

    </div>
    </>
  )
}

export default Navbar;

