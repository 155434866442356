import "./footer.scss";
// import { FaRegLightbulb } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer">
      {/* <div className="colorOption">
      <FaRegLightbulb />
      </div> */}
        &copy; Shan 2022
    </div>
  )
}

export default Footer